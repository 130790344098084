import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { indicationStatus } from './indications.interface';
import { Router } from '@angular/router';
import { UiAddPatientComponent } from 'src/app/shared/components/ui-add-patient/ui-add-patient.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IndicationsService } from 'src/app/services/indications.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { SignaturePlansNames } from '../signature/signature.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-indications',
  templateUrl: './indications.component.html',
  styleUrls: ['./indications.component.scss']
})
export class IndicationsComponent implements OnInit {

  constructor(
    private router: Router,
    private dialog:MatDialog,
    private indicationsService: IndicationsService,
    private snack: MatSnackBar
  ) { }

  filters = new UntypedFormGroup({
    patient: new UntypedFormControl(null),
    status: new UntypedFormControl(null)
  });

  statusType = indicationStatus
  showFilterStatus = true;

  ngOnInit(): void {
    this.statusType.unshift('Todos');
    this.filters.controls.status.setValue('Todos');
  }

  onChangeStatusFilter(){
    console.log("status", this.filters.value.status)
  }

  async goToSaveIndication(id:number = null){
    const indication = id || 'novo';

    const signature = LocalStorageUtil.getSignature()
    const planName = signature.identifier

    // Verifica a quantidade de indicações somente no plano free
    if(indication === 'novo' && planName == SignaturePlansNames.FREE){
      const indicationCount = await this.indicationsService.countIndicationsByAccount().toPromise(); 
    
      if(indicationCount >= 5) {
        this.snack.open('Seu plano atingiu o limite máximo de agendamentos.', 'OK', {
          duration: 5000
        })
        return
      }
    }

    this.router.navigate([`/indications/${indication}`]);
  }

  onChangePaciente(patient:any){
    if("isTrusted" in patient)
      return;

    this.filters.controls.patient.setValue(patient);
  }

  onTabChange(event: MatTabChangeEvent) {
    const selectedTabLabel = event.tab.textLabel;
    this.showFilterStatus = selectedTabLabel !== 'Concluídos';
  }

  addPatient(){
    const dialogRef = this.dialog.open(UiAddPatientComponent,{
      disableClose: false
    })

    dialogRef.afterClosed().subscribe(()=>{})
  }

}
