import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IndicationsService } from 'src/app/services/indications.service';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { AgendaOnlineModalComponent } from '../agenda-online-modal/agenda-online-modal.component';
import { copyToClipboard } from 'src/app/util/string-utils';
import { ConfirmationDialog, UiConfirmationDialogComponent } from 'src/app/shared/components/ui-confirmation-dialog/ui-confirmation-dialog.component';
import { IIndication, IndicationsScheduleStatus } from '../models/IIndication';
import { Patient } from 'src/app/interfaces/patient.interface';
import { UiSelectDocumentComponent } from 'src/app/shared/components/ui-select-document/ui-select-document.component';
import * as moment from 'moment';

@Component({
  selector: 'app-indications-list',
  templateUrl: './indications-list.component.html',
  styleUrls: ['./indications-list.component.scss']
})
export class IndicationsListComponent implements OnInit, OnChanges {

  constructor(
    private router: Router,
    private loginService: LoginService,
    private indicationService: IndicationsService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }
  @Input() tab: 'schedule' | 'attendance';
  @Input() patient: Patient = null;
  @Input() status: string = null;

  loading = false;
  dataSource:  MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns: string[] = ['patient', 'cpf', 'scheduleDate', 'status', 'action'];
  scheduleStatus = IndicationsScheduleStatus;
  private firstLoading = true

  ngOnChanges(changes: SimpleChanges): void {
    if((changes.patient && changes.patient.currentValue) ||  (changes.status && changes.status.currentValue))
      this.filter();
  }

  ngOnInit(): void {
  }

  autoOpenScheduleModal(){
    if(this.activatedRoute.snapshot.params['patient']) {
      const indication = {
        communicarePatientId: this.activatedRoute.snapshot.params['patient'],
      } as IIndication

      this.openAgendaOnlineModal(indication, false, true)
    }
  }

  filter(){
    this.loading = true;

    const params = {
      type: this.tab,
      patientId: this.patient?.id || null,
      status: this.status == 'Todos'? null : this.status
    }

    this.indicationService.getAccountIndications(params).subscribe(
      res=>{
        if(res.status)
          this.dataSource.data = res.data;
        else
          this.loginService.showMessage('Erro ao buscar lista de indicações', 3000);

        if(this.firstLoading){
          this.autoOpenScheduleModal()
        }

        this.loading = false;
        this.firstLoading = false
      },
      error=>{
        this.loginService.showMessage('Erro ao buscar lista de indicações', 3000);
        this.loading = false;

        if(this.firstLoading) {
          if(this.activatedRoute.snapshot.params['patient']) {
            this.router.navigate(['indications']);
          }
        }

        this.firstLoading = false
      }
    );
  }

  goToSaveIndication(id:number = null){
    const indication = id || 'novo';
    this.router.navigate([`/indications/${indication}`]);
  }

  openAgendaOnlineModal(indication: IIndication, isReschedule = false, backToIndications = false) {
    const dialogRef = this.dialog.open(AgendaOnlineModalComponent, {
      disableClose: true,
      data: {
        showSuccessMessage: false,
        communicarePatientId: indication.communicarePatientId,
        rescheduleId: isReschedule ? indication.communicareScheduleId : null,
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if(backToIndications) {
        this.router.navigate(['indications']);
      } else {
        this.filter();
      }
    });
  }

  copyLink(link: string, type: 'teleconsultation' | 'payment'){
    if(link == null) {
      const message = type == 'teleconsultation'? 'Procedimento escolhido não é de teleconsulta' : 'Link de pagamento não encontrado';
      this.loginService.showMessage(message)
      return
    }
    copyToClipboard(link)

    this.loginService.showMessage(`Link da ${type == 'teleconsultation'? 'teleconsulta': 'pagamento'} copiado!`)
  }

  cancelSchedule(indicationId: number, scheduleId: number){
    const dialogRef = this.dialog.open(UiConfirmationDialogComponent, {
      data: {
        title: 'Atenção!',
        question: `Tem certeza que deseja cancelar o agendamento na Communicare? Será necessário solicitar o estorno via suporte`,
        confirm: 'Confirmar',
        cancel: 'Cancelar'
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialog) => {
      if (result.confirm) {
        this.cancelScheduleOnCommunicare(indicationId, scheduleId)
      }
    });
  }

  cancelScheduleOnCommunicare(indicationId: number, scheduleId: number){
    this.loading = true

    this.indicationService.cancelScheduleOnCommunicare(indicationId, scheduleId)
      .subscribe(r => {
        this.loginService.showMessage('Agendamento cancelado com sucesso')
        this.filter()
        this.loading = false
      }, (error) => {
        this.loginService.showMessage('Erro ao cancelar agendamento na Communicare, tente novamente')
        this.loading = false
      })
  }

  openDocument(documentUrl: string){
    if(!documentUrl) {
      this.loginService.showMessage('Laudo pendente')
      return
    }

    const documents = documentUrl.split(',');

    if(documents.length == 1){
      window.open(documents[0], '_blank');
      return;
    }

    const dialogRef = this.dialog.open(UiSelectDocumentComponent, {
      data: {
       documents
      },
      width: '450px',
      height: '350px',
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialog) => {
      if (result.confirm) {
        dialogRef.close()
      }
    });

  }

  // to-do: Não chamar essa função no html, em vez disso, armazenar direto no vetor de indicações para pesar menos o front-end
  isNewDate(
    scheduleDate: string, // format 'DD/MM/YYYY hh:mm', ex: 20/09/2024 12:30
  ){
    if(!scheduleDate) {
      return false
    }

    // Essa tratativa serve para que o botão só fique vermelho para laudos não emitidos após 20/09/2024
    const isAfter = moment(scheduleDate, 'DD/MM/YYYY hh:mm').isAfter(moment('2024-09-20', 'YYYY-MM-DD'));
    return isAfter
  }

}
